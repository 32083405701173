<template>
  <div>
      <b-row>
          <b-col cols="12">
              <b-card class="my-card m-0">
                  <b-table 
                  striped
                  small
                  id="myTables"
                  bordered
                  selectable
                  selected-variant="primary"
                  select-mode="single"
                  responsive="sm"
                  :items="assigment"
                  :fields="fields"
                  @row-dblclicked="test"
                  head-row-variant="secondary"
                  >
                  <template #cell(checkbox)="row">
                      <b-form-checkbox
                      v-model="row.detailsShowing"
                      plain
                      class="vs-checkbox-con"
                      @change="row.toggleDetails"
                      >
                      </b-form-checkbox>
                  </template>
                  <template #cell(performer_first_name)="data">
                      {{ data.item.performer_first_name + ' ' + data.item.performer_last_name }}
                  </template>
                  <template #cell(performer_login)="data">
                    <router-link :to="{ name: 'infoDriver', query: {filter: data.item.performer_id} }">
                      <div style="color: blue;">
                        {{ data.item.performer_login }}
                      </div>
                    </router-link>
                  </template>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
                  </b-table>
              </b-card>
          </b-col>
      </b-row>
  </div>
</template>

<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
export default {
  data(){
      return{
          assigment: [],
          fields: [
          {key: 'checkbox', label: '', thStyle: {width: '30px'}},
          {key: 'order_division_id', label: 'Подразделение', sortable: true, thStyle: {width: '130px'}},
          {key: 'performer_division_id', label: 'Подразделение водителя', sortable: true, thStyle: {width: '130px'}},
          {key: 'order_id', label: 'ID заказа', sortable: true, thStyle: {width: '130px'}},
          {key: 'status_name', label: 'Статус', sortable: true, thStyle: {width: '130px'}},
          {key: 'result', label: 'Результат', sortable: true, thStyle: {width: '130px'}},
          {key: 'result_info', label: 'Результат(название)', sortable: true, thStyle: {width: '160px'}},
          {key: 'performer_first_name', label: 'ФИО водителя', sortable: true, thStyle: {width: '130px'}},
          {key: 'performer_login', label: 'Логин', sortable: true, thStyle: {width: '130px'}},
          {key: 'distance', label: 'Дистанция', sortable: true, thStyle: {width: '130px'}},
          {key: 'rating', label: 'Рейтинг', sortable: true, thStyle: {width: '130px'}},
          {key: 'order_commission', label: 'Коммиссия', sortable: true, thStyle: {width: '130px'}},
          {key: 'driver_balance', label: 'Баланс водителя', sortable: true, thStyle: {width: '130px'}},
          {key: 'request_order_at', label: 'Время запроса', sortable: true, thStyle: {width: '130px'}},
          {key: 'created_at', label: 'Дата создания', sortable: true, thStyle: {width: '130px'}},
          {key: 'lat', label: 'Широта', sortable: true, thStyle: {width: '130px'}},
          {key: 'lng', label: 'Долгота', sortable: true, thStyle: {width: '130px'}},
      ],
      }
  },
  methods:{
      test(){
          let division_id = JSON.parse(localStorage.getItem('userData'))
      }
  },
  mounted(){
      this.$store.commit('pageData/setdataCount', null)
      resizeable()
      this.$http
          .get(`report/assigment-log?filter_order_id=${this.$route.query.filter}`)
          .then(res => {
              this.assigment = res.data
              this.$store.commit('pageData/setdataCount', this.assigment.length)
          })
  },
  watch: {
    '$route.query.filter': function(newPage, oldPage) {
            if (newPage !== oldPage) {
          this.$http
                .get(`report/assigment-log?filter_order_id=${this.$route.query.filter}`)
                .then(res => {
                    this.assigment = res.data
                    this.$store.commit('pageData/setdataCount', this.assigment.length)
                })
            }
        }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-context.scss';
.my-card .card-body{
  padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
.my-card{
  overflow: auto; 
  height: 530px;
  }
}
table{
  outline: none; 
  table-layout: fixed; 
  -webkit-user-select: none;
  overflow: hidden;
  position: relative;
}
tr, td, th{
  padding: 0;
}
td, th{ 
  width: 100px; 
  white-space: nowrap; 
  overflow: hidden;  
  text-align: left; 
  box-sizing: border-box; 
  min-width: 60px;
  }
.my-danger{
  background-color: #f9cfcf;
}  
.my-success{
  background-color: #8fe2b4;
} .my-secondary{
  background-color: #dcdddf;
} .my-warning{
  background-color: #ffcd9d;
}   
</style> 